<template>
  <div class="editor">
    <!-- <div class="row no-gutters sticky-top animate__animated animate__slideInLeft">
      <div class="col">
        <a href="https://8card.net/my_profile/online_exchange?utm_source=generator&utm_medium=banner&utm_campaign=ol" target="_blank"><img src="../assets/images/eight_banner.png" alt=""></a>
      </div>
    </div> -->

    <b-form class="p-3">
      <FormBackgroundImage />
      <FormCorpLogo />
      <FormBackgroundColor />
      <FormTextColor />

      <FormFontFamily_boston v-if="setting.appId == 'boston'" />
      <FormFontFamily v-if="setting.appId != 'boston'" />
      <div style="color: transparent; height: 0; overflow: hidden;">
        <div
          :style="'font-family: '+opt.font+';'"
          v-for="(opt, key) in option.fontfamily"
          :key="key"
        >
          {{ edit.department }}
          {{ edit.title }}
          {{ edit.nameMain }}
          {{ edit.nameSub }}
          {{ edit.university }}
          {{ edit.uni_faculty }}
          {{ edit.uni_department }}
          {{ edit.motto }}
          {{ edit.slogan }}
          {{ edit.mail }}
        </div>
        <div
          :style="'font-family: '+opt.font+'; font-weight: bold;'"
          v-for="(opt, key) in option.fontfamily"
          :key="key"
        >
          {{ edit.department }}
          {{ edit.title }}
          {{ edit.nameMain }}
          {{ edit.nameSub }}
          {{ edit.university }}
          {{ edit.uni_faculty }}
          {{ edit.uni_department }}
          {{ edit.motto }}
          {{ edit.slogan }}
          {{ edit.mail }}
        </div>
      </div>

      <FormDepartment v-if="setting.appId != 'mynavi'" />
      <FormTitle v-if="setting.appId != 'mynavi'" />
      <FormBeforeName_mynavi v-if="setting.appId == 'mynavi'" />
      <FormNameMain />
      <FormNameSub />
      <FormAfterName_mynavi v-if="setting.appId == 'mynavi'" />
      <FormMail />
      <FormSns />
    </b-form>
  </div>
</template>

<script>
// import { saveAs } from 'file-saver'
import FormBackgroundImage from '../components/forms/FormBackgroundImage'
import FormCorpLogo from '../components/forms/FormCorpLogo'
import FormBackgroundColor from '../components/forms/FormBackgroundColor'
import FormTextColor from '../components/forms/FormTextColor'
import FormFontFamily_boston from '../components/forms/FormFontFamily_boston'
import FormFontFamily from '../components/forms/FormFontFamily'
import FormDepartment from '../components/forms/FormDepartment'
import FormTitle from '../components/forms/FormTitle'
import FormBeforeName_mynavi from '../components/forms/FormBeforeName_mynavi'
import FormNameMain from '../components/forms/FormNameMain'
import FormNameSub from '../components/forms/FormNameSub'
import FormAfterName_mynavi from '../components/forms/FormAfterName_mynavi'
import FormMail from '../components/forms/FormMail'
import FormSns from '../components/forms/FormSns'

export default {
  name: 'editor',
  props: {
    // msg: String
  },
  computed: {
    setting() {
      return this.$store.state.setting
    },
    edit() {
      return this.$store.state.edit
    },
    style() {
      return this.$store.state.style
    },
    option() {
      return this.$store.state.option
    }
  },
  components: {
    FormBackgroundImage,
    FormCorpLogo,
    FormBackgroundColor,
    FormTextColor,
    FormFontFamily_boston,
    FormFontFamily,
    FormDepartment,
    FormTitle,
    FormBeforeName_mynavi,
    FormNameMain,
    FormNameSub,
    FormAfterName_mynavi,
    FormMail,
    FormSns,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
